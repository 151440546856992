import { RouteRecordRaw } from "vue-router";

import { getLinks } from "@/constants/links";
import UsersListView from "@/modules/users/UsersListView/UsersListView.vue";

export const USERS_LIST_ROUTE: RouteRecordRaw = {
  path: "/users",
  component: UsersListView,
  name: "Users List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().USERS],
  },
};
