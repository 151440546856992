import { request, ResponseBody } from "@tager/admin-services";

import {  UserInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getUsersCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/users/count" });
}

export function getUsers(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<UserInterface>>> {
  return request.get({ path: "/admin/users", params });
}
