
import {defineComponent, onMounted} from "vue";

import {
  useDataTable,
  DataTable,
  BaseButton,
  DeleteIcon,
  EditIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getUsers } from "../requests";
import { UserInterface } from "../typings";

import { COLUMN_DEFS } from "./UsersListView.helpers";

export default defineComponent({
  name: "UsersListView",
  components: {
    Page,
    DataTable,
    BaseButton,
    DeleteIcon,
    EditIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading: isDataLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<UserInterface>({
      fetchEntityList: (params) =>
        getUsers({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Users List",
    });

    onMounted(() => fetchEntityList());

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    };
  },
});
