import { createWebHistory, type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import {
  SETTINGS_ITEM_FORM_ROUTE,
  SETTINGS_ITEM_LIST_ROUTE,
} from "@tager/admin-settings";

import {
  FILES_FORM_ROUTE,
  FILES_LIST_ROUTE,
} from "@tager/admin-files";

import {
  PAGE_COMMANDS_DETAILS_ROUTE, PAGE_COMMANDS_LOG_DETAILS_ROUTE, PAGE_COMMANDS_LOGS_ROUTE, PAGE_COMMANDS_ROUTE
} from "@tager/admin-cron";

import Home from "@/views/Home.vue";

import {USERS_LIST_ROUTE} from '@/modules/users'

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: () => [{ url: "/", text: "Home" }],
  },
};


export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes: [
    HOME_ROUTE,

    ROLE_FORM_ROUTE,
    ROLE_LIST_ROUTE,
    ADMIN_LIST_ROUTE,
    ADMIN_FORM_ROUTE,
    SETTINGS_ITEM_LIST_ROUTE,
    SETTINGS_ITEM_FORM_ROUTE,

    USERS_LIST_ROUTE,

    FILES_FORM_ROUTE, FILES_LIST_ROUTE,

    PAGE_COMMANDS_DETAILS_ROUTE, PAGE_COMMANDS_LOG_DETAILS_ROUTE, PAGE_COMMANDS_LOGS_ROUTE, PAGE_COMMANDS_ROUTE
  ],
});
