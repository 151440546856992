
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGrid, NavigationGridItem } from "@tager/admin-ui";

import { getLinks } from "@/constants/links";
import {useResource} from "@tager/admin-services";
import {getFilesCount} from "@tager/admin-files";
import {getUsersCount} from "@/modules/users";

export default defineComponent({
  name: "PageHome",
  components: { NavigationGrid },
  setup() {
    const links = computed(() => getLinks());

    const [
      fetchUsersCount,
      { data: usersCountData, status: usersCountDataStatus },
    ] = useResource({
      fetchResource: () => getUsersCount(),
      resourceName: "Users Count",
      initialValue: null,
    });


    const [
      fetchFilesCount,
      { data: filesCountData, status: filesCountDataStatus },
    ] = useResource({
      fetchResource: () => getFilesCount(),
      resourceName: "Files Count",
      initialValue: null,
    });

    onMounted(() => {
      fetchFilesCount();
      fetchUsersCount();
    });

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.USERS.text,
          url: links.value.USERS.url,
          total: {
            status: usersCountDataStatus.value,
            value: usersCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: links.value.FILES.text,
          url: links.value.FILES.url,
          total: {
            status: filesCountDataStatus.value,
            value: filesCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: "Settings",
          url: links.value.SETTINGS.url,
          width: 50,
        },
        {
          name: "Administrators",
          url: links.value.ADMIN_ADMINS.url,
          width: 50,
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
