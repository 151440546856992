import { ColumnDefinition } from "@tager/admin-ui";

import { UserInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<UserInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "E-Mail",
    field: "email",
  },
  {
    id: 3,
    name: "Adapty Profile ID",
    field: "adaptyProfileId",
  },
  {
    id: 4,
    name: "Created",
    field: "createdAt",
   type: 'datetime'
  },
];
