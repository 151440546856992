
import { computed, defineComponent } from "vue";

import { HomeIcon, SettingsIcon, ViewListIcon } from "@tager/admin-ui";
import { MenuItemType, PageLayout } from "@tager/admin-layout";

import { getLinks } from "@/constants/links";

export default defineComponent({
  name: "BaseApp",
  components: { PageLayout },
  setup() {
    const links = computed(() => getLinks());

    const sidebarMenuList: Array<MenuItemType> = [
      { id: "home", icon: HomeIcon, ...links.value.HOME },

      { id: "users", icon: ViewListIcon, ...links.value.USERS },
      { id: "files", icon: ViewListIcon, ...links.value.FILES },
      { id: "settings", icon: ViewListIcon, ...links.value.SETTINGS },

      {
        id: "admins",
        text: "Administrators",
        icon: SettingsIcon,
        children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});
