import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import { getSettingItemListUrl } from "@tager/admin-settings";
import { getFilesListUrl } from "@tager/admin-files";
import {
  getCommandsListUrl,
  getCronLogsUrl,
  getCommandsLogsUrl,
} from "@tager/admin-cron";

type LinkKey =
  | "HOME"
  | "USERS"
  | "SETTINGS"
  | "FILES"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS"
  | "CRON_COMMANDS"
  | "CRON_LOGS"
  | "CRON_COMMANDS_LOGS";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Home",
  },
  USERS: {
    url: "/users",
    text: "Users",
  },
  FILES: {
    url: getFilesListUrl(),
    text: "Files",
  },
  SETTINGS: {
    url: getSettingItemListUrl(),
    text: "Settings",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Roles",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Administrators",
  },
  CRON_COMMANDS: {
    url: getCommandsListUrl(),
    text: "Commands",
  },
  CRON_COMMANDS_LOGS: {
    url: getCommandsLogsUrl(),
    text: "Commands Logs",
  },
  CRON_LOGS: {
    url: getCronLogsUrl(),
    text: "Cron Logs",
  },
});
